<template>
    <div>
        <a-config-provider :locale="zh_CN">
            <a-card>
                <a-row style="margin-bottom: 20px;">
                    <a-col :span="10">
                    </a-col>

                    <a-col :span="14" class="d-flex justify-content-end">
                        <a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText"
                                        enterButton
                                        style="width: 200px;margin-left: 10px;"
                                        @search="filteronchange"></a-input-search>
                    </a-col>
                </a-row>
                <a-spin :spinning="isLoading">
                    <a-table :columns="columns" :data-source="tableData" :customRow="customRow"
                             :row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
                             :rowKey="(record)=>record.id" :scroll="{x:800,y:scroll_x}" :pagination="false"
                             size="small">

                        <span slot="description" slot-scope="text,record">
                            <span v-text="record.description"></span>
						</span>

                        <span slot="enable" slot-scope="text">
								<span v-if="text"><a-icon type='check' style="color: #1890ff;"/></span>
								<span v-else><a-icon type='close'/></span>
							 </span>

                        <span slot="action" slot-scope="text,record">
                            <a @click.stop="createOrEditMachineSupplier(record)" v-if="isGranted('audit_message_edit')">
                                            <a-icon type="edit"/>{{l('Edit')}}
                                        </a>
                            							<font v-if="isGranted('audit_message_enable')&&isGranted('audit_message_edit')"> | </font>
                            							<a-popconfirm v-if="record.enable==true&&isGranted('audit_message_enable')" :title="l('whether_to_disable_this_audit_item')" @confirm="() => handleEdit(record)">
										<a  v-if="record.enable" style="color: red;">
											<a-icon type="close-circle" />{{l('disable')}}
										</a>
									 </a-popconfirm>
									 <a-popconfirm v-if="record.enable==false&&isGranted('audit_message_enable')" :title="l('this_review_item')" @confirm="() => handleEdit(record)">
										<a  v-if="record.enable==false">
											<a-icon type="check-circle" />{{l('enable')}}
										</a>
									 </a-popconfirm>
						</span>
                    </a-table>
                </a-spin>
            </a-card>
        </a-config-provider>
    </div>
</template>

<script>
    import {
        AppComponentBase
    } from "@/shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        MessagePushServiceProxy
    } from '../../../shared/service-proxies';
    import {
        ModalHelper
    } from '@/shared/helpers';
    import CreatErrorCode from "./Message/Message";
    import moment from "moment";

    let _this;
    export default {
        name: "Message-push",
        mixins: [AppComponentBase],
        components: {
            CreatErrorCode,
        },
        created() {
            _this = this;
            this.MessagePushServiceProxy = new MessagePushServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData()
        },
        data() {
            return {
                zh_CN,
                isEdit: false,
                isLook: false,

                filterText: undefined,
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {
                    maxResultCount: 10,
                    skipCount: 0
                },

                tableData: [],
                columns: [
                    {
                        title: this.l('Title'),
                        width: 150,
                        dataIndex: 'title',
                        align: 'left',
                        ellipsis: true,
                    },
                    {
                        title: this.l('NewsContent'),
                        width: 300,
                        dataIndex: 'description',
                        align: 'left',
                        ellipsis: true,
                        scopedSlots: {
                            customRender: "description"
                        },
                    },
					// {
					//     title: this.l('跳转页面URL'),
					//     width: 150,
					//     dataIndex: 'jumpUrl',
					//     align: 'left',
					//     ellipsis: true,
					// },
                    {title: this.l('enable'),width: 50,dataIndex: 'enable',align: 'center',scopedSlots: {customRender: "enable"}},
                    {
                        title: this.l('Actions'),
                        width: 150,
                        dataIndex: 'action',
                        align: 'center',
                        scopedSlots: {
                            customRender: "action"
                        },
                        fixed: 'right',
                    },
                ],
                selectedRowKey: [], // 选择的数据Id
                selectedRow: [], //选择的整行数据
                isLoading: false, //加载中
                children: [],
            }
        },
        methods: {

            handleEdit(record) {
                // console.log(record);
                if(record.enable==true){
                    this.enable = false;
                }else {
                    this.enable = true;
                }
                this.isLoading = true;
                this.MessagePushServiceProxy.enable(
                    record.id,
                    this.enable
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    this.getData();
                    this.selectedRowKey = [];
                })
            },

            //获取数据
            getData() {
                this.selectedRowKey = [];
                this.isLoading = true;
                this.MessagePushServiceProxy.getPaged(
                    this.filterText ? this.filterText : undefined,
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.tableData = res.items;
                    this.tableData.map(item => {
                        let regex = /(<([^>]+)>)/ig;
                        item.description = item.description.replace(regex, "");
                    });
                    // console.log(this.tableData);
                })
            },
            //行选择
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            _this.onSelect(record)
                        }
                    }
                }

            },
            //多选
            SelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.tableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectedRowKey.find(t => t == item.id);
                        if (!u) {
                            this.selectedRowKey = [...this.selectedRowKey, item.id];
                        }
                    });
                } else {
                    //更改表格数据勾选状态
                    this.tableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectedRowKey = [];
                    });
                }
            },
            // 重写复选框点击事件(不可删除)
            onSelect(record) {
                //debugger;
                console.log(record);
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKey.push(record.id);
                    this.children.push(record.children);
                } else {
                    this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);
                    this.children = _.difference(_this.children, [record.children]);
                }
            },
            //搜索框点击事件
            filteronchange() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            //新增
            createOrEdit(cases) {
                ModalHelper.create(CreatErrorCode, {
                    isEdit: false,
                    id: undefined
                }, {
                    width: 1100
                }).subscribe(res => {
                    console.log(res)
                    if (res) {
                        this.getData();
                    }
                    this.selectedRowKey = [];
                })
            },
            //编辑
            createOrEditMachineSupplier(record) {
                console.log(record)
                ModalHelper.create(CreatErrorCode, {
                    isEdit: true,
                    id: record.id
                }, {
                    width: 1100
                }).subscribe(res => {
                    console.log(res)
                    if (res) {
                        this.getData();
                    }
                    this.selectedRowKey = [];
                })
            },
            //批量删除事件
            batchdelete() {

            },
            //删除
            deletetable(record) {

            },
        }
    }
</script>

<style>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
