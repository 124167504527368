<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px"/>
                <span>{{this.entity.name}}消息模板配置</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>

                    <a-col :span="18">
                        <a-row>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="2" style="text-align: right">
                                    <a-form-item :label="l('标题')">

                                    </a-form-item>
                                </a-col>
                                <a-col :span="20">
                                    <a-input v-model="entity.title" class="input" style="color: black;width: 100%;"></a-input>
                                    <br>
                                    <span>示例：尊敬的{xxx}，有一条新消息。（要带上大括号，字段名可参考下方字段配置参考）也可不带参数。每日消息提醒共用一个消息标题</span>
                                </a-col>
                            </a-col>
							<a-col :span="24" style="margin-left: 21px;margin-bottom: -20px;">
							    <a-col :span="2" style="text-align: right">
							        <a-form-item :label="l('URL')">
							
							        </a-form-item>
							    </a-col>
							    <a-col :span="20">
							        <a-input v-model="entity.jumpUrl" class="input" style="color: black;width: 100%;"></a-input>
								</a-col>
							</a-col>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="2" style="text-align: right">
                                    <a-form-item :label="l('NewsContent')">
                                    </a-form-item>
                                </a-col>
                                <a-col :span="20">
									<!-- <editor v-model="entity.description" @onChange="editorOnChang"></editor> -->
									<a-textarea v-model="entity.description" class="input" style="color: black;width: 100%;" :rows="14"/>
                                </a-col>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="6">
                        <a-row>
                            <a-col :span="24">
                                <a-col :span="24" style="text-align: left;">
                                    {{l("字段配置参考(点击字段可复制字段):")}}
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'NewProb'" v-for="item in NewProbstr.split(',')" :key="item">
                                    <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'ProgressChange'" v-for="item in ProgressChangestr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'TaskTurn'" v-for="item in IssueTurnbstr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'IssueTurn'" v-for="item in TaskTurnstr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'DailyMessageInTodayPlan'" v-for="item in DailyMessagestr1.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'DailyMessageInProbWillOutTime'" v-for="item in DailyMessagestr2.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'DailyMessageInOutTimeProb'" v-for="item in DailyMessagestr3.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.type == 'IssueEdit'" v-for="item in IssueEditstr.split(',')" :key="item">
                                    <template >
                                        <template>
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                            </a-col>
                        </a-row>
                    </a-col>


                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {MessagePushServiceProxy,MessagePushEditDto,CreateOrUpdateMailTemplateInput} from '../../../../shared/service-proxies';
    import Editor from "../../../../components/editor/editor";
    import VueClipboard from 'vue-clipboard2'

    let _this;
    export default {
        name: 'Message',
        mixins: [ModalComponentBase],
        components: {
            Editor,
            VueClipboard
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.MessagePushServiceProxy = new MessagePushServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if (this.isEdit){
                this.getData();
            }
            // this.entity.code = "NewProb";
            // this.entity.enable = true;
        },
        data() {
            return {
                zh_CN,
                id:undefined,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
				isclick: true,
                entity: new MessagePushEditDto,
                NewProbstr:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                ProgressChangestr:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                IssueTurnbstr:"转办人{FromUserName},代办人{ToUserName},问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                TaskTurnstr:"转办人{FromUserName},代办人{ToUserName},审核人{InspectorName},计划日期{FomartDateTime},状态{AuditTaskStateName},任务名称{Name},任务类型{AuditTaskTypeName},当前时间@time,场景@scene,企业@tenant",
                DailyMessagestr1:"任务类型{AuditTaskTypeName},审核模板名{AuditTemplateName},审核人{InspectorName},计划日期{FomartDateTime},实际开始日期{StartTime},实际结束日期{EndTime},状态{AuditTaskStateName},备注{Remark},任务名称{Name},当前时间@time,场景@scene,企业@tenant",
                DailyMessagestr2:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                DailyMessagestr3:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                IssueEditstr:"问题修改记录较特殊，建议严格按照要求修改。{系统字段}在{系统字段}修改了{系统字段}问题。例：张三在xx天xx时xx分修改了XXX问题。只需修改不是{系统字段}的文字即可",
            }
        },
        methods: {

            CopyText(e) {
                let createInput = document.createElement("input");
                let regex3 = /\{(.+?)\}/g; // {} 花括号，大括号
                let ss = e.match(regex3)
                createInput.value = ss[0].replace("{","").replace("}","");
                document.body.appendChild(createInput);
                createInput.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                createInput.style.display = "none";
                this.$message.success('复制成功');
            },

            cancel(e) {
                this.$message.error('取消复制');
            },

            onChange(checked) {
                console.log(`a-switch to ${checked}`);
            },


            change(value) {
                console.log(value)
                if(value == undefined){
                    this.entity.code = undefined;
                }
                else{
                    this.entity.code = value.key;
                    this.entity.name = value.label;
                }
                console.log(this.entity)
            },

            btnclick(){
                if (this.theadstr == undefined || this.theadstr ==""){
                    abp.message.info('没有输入信息，无法查看效果');
                    return;
                }
                let table = "<table border='1' style='border-collapse: collapse; width: 100%;text-align: center;'>";

                let theadstrArr = this.theadstr.split(',');
                let tbodystrArr = this.tbodystr.split(',');

                let s1 = "";
                s1 += "<thead><tr>";
                for (let i = 0; i < theadstrArr.length; i++) {
                    s1 += "<th>"+theadstrArr[i]+"</th>";
                }
                s1 += "</tr></thead>";


                let s2 = "";
                s2 += "<tbody><tr>";
                for (let i = 0; i < tbodystrArr.length; i++) {
                    s2 += "<td>"+tbodystrArr[i]+"</td>";
                }
                s2 += "</tr></tbody>";
                table += s1;
                table += s2;
                table+="</table>";
                this.tablestr = table;
                console.log(table)
                console.log(s1)
                console.log(s2)
            },

            getData() {
                this.isLoading = true;
                this.MessagePushServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
                })
            },
            editorOnChang(e) {
                this.entity.description = e;
            },
            save() {

                if (this.entity.description == undefined || this.entity.description  ==""){
                    abp.message.info('请输入消息模板内容');
                    return;
                }

                if (this.entity.title == undefined || this.entity.title  ==""){
                    abp.message.info('请输入消息标题');
                    return;
                }

                // let sum= this.entity.content.indexOf("@table");
                // let table = "<table border='1' style='border-collapse: collapse; width: 100%;text-align: center;'>";

                
                console.log(this.entity)
				if(this.isclick) {
					this.isclick = false;
					this.MessagePushServiceProxy.createOrUpdate(
					    this.entity
					).finally(() => {
					}).then(res => {
						this.isclick = true;
					    this.success(true)
					})
				}
            },

        }
    }
</script>

<style>
    .input {
        width: 200px;
    }
</style>
